angular.module('app')

.directive('thumbnailOverlay', function () {

    return {
        restrict: 'A',
        scope: {
            thumbnailOverlayParams: '='
        },
        link: function (scope, element) {

            var hoverInClass    = 'hover-in',
                hoverOutClass   = 'hover-out';

            element.on('mouseenter', function () {
                element.removeClass(hoverOutClass).addClass(hoverInClass);
            });
            element.on('mouseleave', function () {
                element.removeClass(hoverInClass).addClass(hoverOutClass);
            });

        }
    };

});
