angular.module('app')

.controller('newsCtrl', function ($scope, posts, Shuffle, monthNames) {

    // Private vars go here
    var gridShuffle;
    var postDefaultFilter = 'All';
    var currentYear = new Date().getFullYear();


    /**
     * Return parsed date array
     * Working with dates with (yyyy-mm-dd H:i:s) format
     * @param {string} date
     * @returns {object}
     */
    var getDateDetails = function (date)
    {
        // Split date & time
        var datePieces = date.split(' ');

        if (datePieces.length !== 2) {
            return null;
        }

        var parsedDate = datePieces[0].split('-');

        if (parsedDate.length !== 3) {
            return null;
        }

        return {
            year: Number(parsedDate[0]),
            month: Number(parsedDate[1]),
            day: Number(parsedDate[2]),
        };
    };

    // Scope vars go here
    $scope.currentFilter = postDefaultFilter;
    $scope.filterOptions = [postDefaultFilter, 2018, 2017];
    $scope.posts = posts;

    /**
     *
     * @param {string} date
     * @returns {string}
     */
    $scope.formatPostDate = function(date)
    {
        var parsedDate = getDateDetails(date);

        if (!parsedDate) {
            return '';
        }

        var monthIndex = parsedDate.month - 1;
        var postDate = parsedDate.day + ' ' + monthNames[monthIndex];

        // Adding year if it's not current one
        postDate += ( currentYear !== parsedDate.year ? ' ' + parsedDate.year : '');

        return postDate;
    };

    $scope.getPostGroups = function (date)
    {
        var parsedDate = getDateDetails(date);

        return parsedDate ? [parsedDate.year, postDefaultFilter] : [postDefaultFilter];
    };

    $scope.filterPostsByGroup = function (group) {

        $scope.currentFilter = group;

        if (gridShuffle) {

            // Filter elements
            gridShuffle.filter(group);
        }
    };

    $scope.$on('imagesLoaded:loaded', function(event, element)
    {
        element.removeClass('ng-hide');
        var grid = element[0].querySelector('.news-grid-body');

        gridShuffle = new Shuffle(grid, {
            easing: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
            group: $scope.currentFilter,

            // Transition speed 0.5 sec
            speed: 500
        });
    });

});
