angular.module('app')

.controller('jobsCtrl', function ($scope, meta, vacanciesOpen) {

    meta.set($scope, {
        title: "TwelfthMan - Jobs"
    });

    $scope.vacanciesOpen = vacanciesOpen;
});
