angular.module('app')


.controller('contactCtrl', function ($scope, CONFIG, MAPBOX, meta, mapMarkers, tweenMax) {

    meta.set($scope, {
        title: "TwelfthMan - Contact"
    });

    $scope.markers = mapMarkers;

    $scope.mapboxConfig = {
        defaults: {
            minZoom: 17,
            maxZoom: 17,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            zoomControlPosition: 'bottomright',
            attributionControl: false,
            tap: true
        },
        center: {
            lat: 51.519298,
            lng: -0.071952,
            zoom: 18
        },
        maxbounds: {
            northEast: {
                lat: 89,
                lng: 180
            },
            southWest: {
                lat: -87,
                lng: -180
            }
        },
        layers: {
            baselayers: {
                mapbox_terrain: {
                    name: 'Twelfthman',
                    type: 'xyz',
                    url: "https://api.mapbox.com/styles/v1/tomaslouro/cihritk3f008cb0kpw7j4g8gc/tiles/{z}/{x}/{y}?access_token=" + MAPBOX.apikey
                }
            }
        }
    };

    $scope.switchOffice = function(newLocation) {
        $scope.mapboxConfig.center.lat = newLocation.lat;
        $scope.mapboxConfig.center.lng = newLocation.lng;
    };

    var londonAnimationObject = {counter: 0};
    var londonAnimationSteps = ["1500px 1800px", "1350px 1800px", "1200px 1800px", "1050px 1800px", "900px 1800px", "750px 1800px", "600px 1800px", "450px 1800px", "300px 1800px", "150px 1800px",
                          "1500px 1650px", "1350px 1650px", "1200px 1650px", "1050px 1650px", "900px 1650px", "750px 1650px", "600px 1650px", "450px 1650px", "300px 1650px", "150px 1650px",
                          "1500px 1500px", "1350px 1500px", "1200px 1500px", "1050px 1500px", "900px 1500px", "750px 1500px", "600px 1500px", "450px 1500px", "300px 1500px", "150px 1500px",
                          "1500px 1350px", "1350px 1350px", "1200px 1350px", "1050px 1350px", "900px 1350px", "750px 1350px", "600px 1350px", "450px 1350px", "300px 1350px", "150px 1350px",
                          "1500px 1200px", "1350px 1200px", "1200px 1200px", "1050px 1200px", "900px 1200px", "750px 1200px", "600px 1200px", "450px 1200px", "300px 1200px", "150px 1200px",
                          "1500px 1050px", "1350px 1050px", "1200px 1050px", "1050px 1050px", "900px 1050px", "750px 1050px", "600px 1050px", "450px 1050px", "300px 1050px", "150px 1050px",
                          "1500px 900px", "1350px 900px", "1200px 900px", "1050px 900px", "900px 900px", "750px 900px", "600px 900px", "450px 900px", "300px 900px", "150px 900px",
                          "1500px 750px", "1350px 750px", "1200px 750px", "1050px 750px", "900px 750px", "750px 750px", "600px 750px", "450px 750px", "300px 750px", "150px 750px",
                          "1500px 600px", "1350px 600px", "1200px 600px", "1050px 600px", "900px 600px", "750px 600px", "600px 600px", "450px 600px", "300px 600px", "150px 600px",
                          "1500px 450px", "1350px 450px", "1200px 450px", "1050px 450px", "900px 450px", "750px 450px", "600px 450px", "450px 450px", "300px 450px", "150px 450px",
                          "1500px 300px", "1350px 300px", "1200px 300px", "1050px 300px", "900px 300px", "750px 300px", "600px 300px", "450px 300px", "300px 300px", "150px 300px",
                          "1500px 150px", "1350px 150px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px",
                          "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px",
                          "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px",
                          "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px",
                          "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px",
                          "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px", "1500px 1800px"];

    var copenhagenAnimationObject = {counter: 0};
    var copenhagenAnimationSteps = ["1500px 1800px", "1350px 1800px", "1200px 1800px", "1050px 1800px", "900px 1800px", "750px 1800px", "600px 1800px", "450px 1800px", "300px 1800px", "150px 1800px",
                                    "1500px 1650px", "1350px 1650px", "1200px 1650px", "1050px 1650px", "900px 1650px", "750px 1650px", "600px 1650px", "450px 1650px", "300px 1650px", "150px 1650px",
                                    "1500px 1500px", "1350px 1500px", "1200px 1500px", "1050px 1500px", "900px 1500px", "750px 1500px", "600px 1500px", "450px 1500px", "300px 1500px", "150px 1500px",
                                    "1500px 1350px", "1350px 1350px", "1200px 1350px", "1050px 1350px", "900px 1350px", "750px 1350px", "600px 1350px", "450px 1350px", "300px 1350px"];

    var londonAnimationUpdate = function() {
        if (londonAnimationObject.counter < londonAnimationSteps.length) {
            tweenMax.to(london, 0, {backgroundPosition: londonAnimationSteps[Math.ceil(londonAnimationObject.counter)]});
        }
    }

    var copenhagenAnimationUpdate = function() {
        if (copenhagenAnimationObject.counter < copenhagenAnimationSteps.length) {
            tweenMax.to(copenhagen, 0, {backgroundPosition: copenhagenAnimationSteps[Math.ceil(copenhagenAnimationObject.counter)]});
        }
    }

    $scope.londonIconAnimation = tweenMax.to(londonAnimationObject, 4, {counter: londonAnimationSteps.length, repeat:-1, paused: true, ease: SteppedEase.config(londonAnimationSteps.length), onUpdate: londonAnimationUpdate});
    $scope.copenhagenIconAnimation = tweenMax.to(copenhagenAnimationObject, 1, {counter: copenhagenAnimationSteps.length, repeat:-1, paused: true, ease: SteppedEase.config(copenhagenAnimationSteps.length), onUpdate: copenhagenAnimationUpdate});

});
