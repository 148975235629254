angular.module('app')

.filter('socialLinky', function ($filter, $sce) {
    return function(text, type, target) {

        if (!text) {
            return text;
        }

        var replacedText = $filter('linky')(text, target);
        var targetAttr = "";
        var hashtagReg = /(^|\s)#(\w*[a-zA-Z_]+\w*)/gim;
        var mentionReg = /(^|\s)\@(\w*[a-zA-Z_]+\w*)/gim;

        if (angular.isDefined(target)) {
            targetAttr = ' target="' + target + '"';
        }

        if(type === 'twitter'){
            replacedText = text.replace(hashtagReg, '$1<a class="hashtag" href="https://twitter.com/search?q=%23$2"' + targetAttr + '>#$2</a>');
            replacedText = replacedText.replace(mentionReg, '$1<a class="hashtag" href="https://twitter.com/$2"' + targetAttr + '>@$2</a>');
        } else if(type === 'facebook'){
            replacedText = text.replace(hashtagReg, '$1<a class="hashtag" href="https://facebook.com/hashtag/$2"' + targetAttr + '>#$2</a>');
        } else if (type === 'instagram') {
            replacedText = text.replace(hashtagReg, '$1<a href="https://www.instagram.com/explore/tags/$2"' + targetAttr + '>#$2</a>');
            replacedText = replacedText.replace(mentionReg, '$1<a href="https://www.instagram.com/$2"' + targetAttr + '>@$2</a>');
        }

        $sce.trustAsHtml(replacedText);

        return replacedText;
    };
});
