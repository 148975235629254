angular.module('app')

.controller('theTeamCtrl', function ($scope, CONFIG, meta, team, mapTabs, vacanciesOpen) {

    meta.set($scope, {
        title: "TwelfthMan - The Team"
    });

    $scope.team = team;
    $scope.mapTabs = mapTabs;
    $scope.thumbsPath = CONFIG.membersThumbs;
    $scope.vacanciesOpen = vacanciesOpen;
});
