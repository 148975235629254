angular.module('app')

.controller('storyVideoCtrl', function ($scope, $rootScope, $uibModalInstance, videoSettings) {

    $scope.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
        $rootScope.noScroll = 'auto';
    };

    // This name is being used to match the json structure from case studies
    // and being able to reuse the partial template
    $scope.section = videoSettings;
});
