angular.module('app')

.directive('socialSharing', function ($window, $location, CONFIG, FB)
{

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'socialSharing/socialSharing.html',
        replace: true,
        scope: {
            shareText: '@',
            shareHashtags: '@',
            shareType: '@'
        },
        link: function (scope, element)
        {

            var shareUrl = $location.absUrl();
            var shareText = scope.shareText || "";
            var shareHashtags = scope.shareHashtags || "TheDifference";
            var popUpWidth = 600;
            var popUpHeight = 500;

            var socialLinks = {
                facebook: "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(shareUrl) + '&description=' + encodeURIComponent(shareText),
                twitter: "https://www.twitter.com/intent/tweet?text=" + encodeURIComponent(shareText) + '&url=' + encodeURIComponent(shareUrl) + '&hashtags=' + encodeURIComponent(shareHashtags),
                linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(shareUrl),
                google: "https://plus.google.com/share?url=" + encodeURIComponent(shareUrl) + '&summary=' + shareText
            };

            scope.share = function (type)
            {

                if (type === 'facebook') {
                    var fb = FB.get();
                    fb.ui({
                        method: 'feed',
                        link: shareUrl,
                        caption: shareText,
                    }, function () {});
                } else {
                    $window.open(socialLinks[type], 'sharer', 'toolbar=0,status=0,width=' + popUpWidth + ',height=' + popUpHeight);
                }
            };

            scope.sendMail = function()
            {
                $window.open("mailto:?subject=" + shareText + "&body=" + shareUrl, "_self");
            };

            var init = function ()
            {
                if (scope.shareType === 'fixed') {

                    // Get distance of element from top
                    var elementOffsetParent = element[0].parentElement.offsetParent;

                    var onScroll = function () {
                        // If element is the necessary distance away from the top and bottom of the page set flag to true.
                        if (this.pageYOffset >= window.innerHeight && this.pageYOffset - 200 <= elementOffsetParent.clientHeight) {
                            scope.elementFixedVisible = true;
                        } else {
                            scope.elementFixedVisible = false;
                        }

                        scope.$apply();
                    };

                    angular.element($window).bind('scroll', onScroll);

                    scope.$on('$destroy', function() {
                        angular.element($window).off('scroll', onScroll);
                    });
                }
            };

            init();
        }

    };

});
