angular.module('app')

.controller('ourStoriesCtrl', function ($scope, $rootScope, $state, $uibModal, meta, stories) {

    // Set meta data to page
    meta.set($scope, {
        title: "TwelfthMan - Our Stories"
    });

    // List of stories
    $scope.stories = stories.stories;
    // Most recent story (last in array)
    var mostRecentStory = $scope.stories[$scope.stories.length - 1];
    // Story being viewed slug
    $scope.storeViewedSlug = $rootScope.state.params && $rootScope.state.params.slug ? $rootScope.state.params.slug : mostRecentStory.slug;
    // Images path
    $scope.imagesPath = '../images/pages/ourstories/' + $scope.storeViewedSlug;
    // Inline styles
    $scope.styles = {
        hero: {
            'background-image': 'url(' + $scope.imagesPath + '/intro-bgr.jpg)'
        }
    };

    // This name is being used to match the json structure from case studies
    // and being able to reuse the partial
    $scope.videoSettings = {
        settings: {
            sources: [
                {
                    src: "https://static.twelfthman.co/twelfthman-website/ourstories/" + $scope.storeViewedSlug + "/video.mp4",
                    type: "video/mp4"
                },
                {
                    src: "https://static.twelfthman.co/twelfthman-website/ourstories/" + $scope.storeViewedSlug + "/video.webm",
                    type: "video/webm"
                }
            ],
            theme: "bower_components/videogular-themes-default/videogular.css",
            plugins: {
                poster: "images/pages/ourstories/" + $scope.storeViewedSlug + "/video-poster.jpg"
            }
        }
    };

    $scope.openStoryVideo = function () {

        $uibModal.open({
            templateUrl: 'app/partials/story.modal.html',
            windowTopClass: 'showreel',
            controller: 'storyVideoCtrl',
            animation: false,
            resolve: {
                videoSettings: function() {
                    return $scope.videoSettings;
                }
            }
        }).result.catch(function () {
            $rootScope.noScroll = 'auto';
        });

        $rootScope.noScroll = 'no-scroll';
    };

    // Helper function for routing
    $scope.goto = function (slug) {
        $state.go('stories', {slug: slug});
    };

});
