angular.module('app')

.factory('inviewOptions', function () {

    return {

        /// Inview directive options
        options: {
            sections: {
                offset: 400
            },
            footer: {
                offset: 100
            }
        },

        /// Element is in view / add class for animation
        show: function (inview, event) {

            if (inview) {

                var target = event.inViewTarget;
                var elemClasses = target.classList;

                if (elemClasses) {
                    elemClasses.add('in-viewport');
                } else {
                    target.className = 'in-viewport';
                }
            }
        }
    };

});
