angular.module('app')

.factory('Page', function ($rootScope, jsonService) {

    return {

        getAll: function (slug) {
            return jsonService.getContent('pages/' + slug);
        },

        getSingle: function (slug) {
            return jsonService.getContent('pages/' + slug);
        },

        getNextAndPrev: function (allWorks, caseStudy) {

            var currentIndex,
                prev = {},
                next = {},
                nextAndPrev = {};

            // Get index of current case study in list
            for (var i = 0; i < allWorks.length; i++) {
                if (allWorks[i].clientslug && allWorks[i].slug &&
                    allWorks[i].clientslug === caseStudy.client &&
                    allWorks[i].slug === caseStudy.slug) {
                        currentIndex = i;
                        break;
                }
            }

            // Get previous and next case study in list
            if (currentIndex === 0) {
                next = allWorks[currentIndex + 1];
                prev = allWorks[allWorks.length - 1];
            } else if (currentIndex > 0 && currentIndex !== allWorks.length - 1) {

                next = allWorks[currentIndex + 1];
                prev = allWorks[currentIndex - 1];

            } else if (currentIndex === allWorks.length - 1) {
                next = allWorks[0];
                prev = allWorks[currentIndex - 1];
            }

            nextAndPrev.next = next;
            nextAndPrev.prev = prev;

            return nextAndPrev;
        }

    };
});
