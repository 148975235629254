// Scroll to element directive,
// adapted from http://stackoverflow.com/a/21918502/257494

// jshint ignore: start

angular.module('app')

.directive('scrollToElement', function($location) {

    return {
        restrict: 'A',
        replace: false,
        scope: {
            'scrollToElement': '@'
        },
        link: function(scope, element, attrs) {

            initialize();

            function initialize() {
                createEventListeners();
            }

            function createEventListeners() {
                // listen for a click
                element.on('click', function() {
                    // set the hash like a normal anchor scroll
                    $location.hash(scope.scrollToElement);

                    // smooth scroll to the passed in element
                    scrollTo(scope.scrollToElement);
                });
            }

            function scrollTo(elementId) {
                var i;
                var startY = currentYPosition();
                var stopY = elmYPosition(elementId);
                var distance = stopY > startY ? stopY - startY : startY - stopY;
                if (distance < 100) {
                    scrollTo(0, stopY); return;
                }
                var speed = Math.round(distance / 100);
                if (speed >= 30) speed = 30;
                var step = Math.round(distance / 25);
                var leapY = stopY > startY ? startY + step : startY - step;
                var timer = 0;
                if (stopY > startY) {
                    for (i = startY; i < stopY; i += step) {
                        setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
                        leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                    } return;
                }
                for (i = startY; i > stopY; i -= step) {
                    setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
                    leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
                }
            }

            function currentYPosition() {
                // Firefox, Chrome, Opera, Safari
                if (window.pageYOffset) {
                    return window.pageYOffset;
                }
                // Internet Explorer 6 - standards mode
                if (document.documentElement && document.documentElement.scrollTop) {
                    return document.documentElement.scrollTop;
                }
                // Internet Explorer 6, 7 and 8
                if (document.body.scrollTop) {
                    return document.body.scrollTop;
                }
                return 0;
            }

            function elmYPosition(elementId) {
                var elm = document.getElementById(elementId);
                var y = elm.offsetTop;
                var node = elm;
                while (node.offsetParent && node.offsetParent != document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                } return y;
            }
        }
    };
});
