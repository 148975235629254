angular.module('app')

.directive('otherStories', function (CONFIG, $rootScope, $state, jsonService) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'otherStories/otherStories.html',
        replace: true,
        scope: false,
        link: function (scope) {

            scope.interval = 0;
            scope.noWrapSlides = false;

            var mostRecentStory;
            var mostRecentStoryIndex;

            function chunk (arr, len) {

                var chunks = [],
                    i = 0,
                    n = arr.length;

                while (i < n) {
                    chunks.push(arr.slice(i, i += len));
                }

                return chunks;
            }

            jsonService.getContent('our-stories/stories').then(function (response) {

                scope.stories = response.data;
                mostRecentStory = scope.stories.stories[scope.stories.mostRecentStory];

                // Story being viewed slug
                scope.storeViewedSlug = $rootScope.state.params && $rootScope.state.params.slug ? $rootScope.state.params.slug : mostRecentStory.slug;
                // Images path
                scope.imagesPath = '../images/pages/ourstories/' + scope.storeViewedSlug;
                // Inline styles
                scope.styles = {
                    hero: {
                        'background-image': 'url(' + scope.imagesPath + '/intro-bgr.jpg)'
                    }
                };

                mostRecentStoryIndex = findIndexByAttribute(scope.stories.stories, 'slug', scope.storeViewedSlug);

                // Remove viewed story from results
                scope.stories.stories.splice(mostRecentStoryIndex, 1);
                scope.stories.desktop = chunk(scope.stories.stories, 3);
            });

            // This name is being used to match the json structure from case studies
            // and being able to reuse the partial
            scope.section = {
                settings: {
                    sources: [
                        {
                            src: "https://static.twelfthman.co/twelfthman-website/ourstories/" + scope.storeViewedSlug + "/video.mp4",
                            type: "video/mp4"
                        },
                        {
                            src: "https://static.twelfthman.co/twelfthman-website/ourstories/" + scope.storeViewedSlug + "/video.webm",
                            type: "video/webm"
                        }
                    ],
                    theme: "bower_components/videogular-themes-default/videogular.css",
                    plugins: {
                        poster: "images/pages/ourstories/" + scope.storeViewedSlug + "/video-poster.jpg"
                    }
                }
            };

            // Helper function for routing
            scope.goto = function (slug) {
                $state.go('stories', {slug: slug});
            };

            var findIndexByAttribute = function(array, attr, value) {
                for(var i = 0; i < array.length; i++) {
                    if(array[i][attr] === value) {
                        return i;
                    }
                }
                return -1;
            };

        }
    };
});
