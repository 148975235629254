angular.module('app')

.factory('FB', function ($window)
{
    return {
        get: function() {
            return $window.FB;
        }
    };
});
