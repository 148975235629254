angular.module('app')

.run(function ($rootScope, meta)
{
    $rootScope.meta = meta;
    meta.setDefaults();
})

.factory('meta', function ($location)
{
    var appIds = {
        'www.twelfthman.co': '1247245535291088',
        'dev.twelfthman.co': '1247838808565094'
    };

    var base = $location.protocol() + '://' + $location.host() + '/';
    var defaults = {
        title: "TwelfthMan",
        description: "TwelfthMan, an agency that combines a love of sport with a passion for design",
        og_url: base,
        og_type: "website",
        og_image: base + "images/utils/twelfthman-share.jpg",
        fb_app_id: appIds[$location.host()]
    };

    return {
        set: function ($scope, meta) {

            meta.og_url = $location.absUrl();
            if (meta.og_image) {
                meta.og_image = base + meta.og_image;
            }

            angular.extend(this, defaults, meta);

            var self = this;
            $scope.$on('$destroy', function () {
                self.setDefaults();
            });
        },
        setDefaults: function () {
            angular.extend(this, defaults);
        }
    };

});
