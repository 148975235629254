angular.module('app')

.controller('christmasCtrl', function ($scope, $rootScope, $timeout, meta, data) {

    var init = function ()
    {
        meta.set($scope, {
            title: "TwelfthMan - Christmas 2017"
        });

        // Adding delay for grid animation
        $timeout(function () {
            $scope.data = data;
        }, 1300);
    };

    init();

});
