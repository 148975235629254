angular.module('app', [
    'ui.router',
    'ui.bootstrap',
    'ngAnimate', // bootstrap carousel dependency
    'ngSanitize',
    'ngTouch',
    'pc035860.scrollWatch',
    'angular-inview',
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'com.2fdevs.videogular.plugins.overlayplay',
    'com.2fdevs.videogular.plugins.poster',
    'angulartics',
    'angulartics.google.analytics',
    'angular-cookie-law',
    'leaflet-directive',
    'hj.imagesLoaded',
    'templates',
])

// Config constants
.constant('CONFIG', {
    partialsPath:   "app/partials/",
    pagesPath:      "app/pages/",
    storiesPath:    "app/pages/stories/",
    contentPath:    "app/js/content/",
    directivesPath: "app/js/directives/",
    membersThumbs:  "images/pages/theteam/team/grid/",
    workImagesPath: "images/pages/work/",
    breakpoints: {
        desktop:  1280,
        tablet:   768,
        mobile:   450
    }
})

.constant('MAPBOX', {
    apikey:      'pk.eyJ1IjoidG9tYXNsb3VybyIsImEiOiJjaWhyaXI1YngwMDBtdzRrcGh4enh6cGR0In0.HWRsQSyYiRKAnfp_fYAT-w',
    tiles_url:   'https://api.tiles.mapbox.com/v4/{mapid}/{z}/{x}/{y}@2x.png?access_token={apikey}',
    places_url:  'https://a.tiles.mapbox.com/geocoding/v5/mapbox.places/',
    mapid:       'twelfthmandigital.382ea64a'
})

.config(function ($locationProvider)
{
    $locationProvider
        .html5Mode(false)
        .hashPrefix('!');
})

.config(function($sceDelegateProvider) {
    // Whitelist same origin resource loads and assets from TM Static repo.
    $sceDelegateProvider.resourceUrlWhitelist(['self', 'https://static.twelfthman.co/**']);
})

.config(function($logProvider) {
    // Disable angular leaflet directive logging.
    $logProvider.debugEnabled(false);
})

.run(function ($rootScope, utils, inviewOptions, $state, $timeout) {

    $rootScope.getPartial = utils.getPartial;
    $rootScope.inviewOptions = inviewOptions;
    $rootScope.state = $state;

    
    $rootScope.$on('$stateChangeStart',

        function (event, toState, toParams, fromState, fromParams) {

            // Current page
            $rootScope.activePage = toState.name;

            if (toParams.slug !== undefined) {
                $rootScope.activePage += ' ' + toParams.slug;
            }

            // In Case Studies pages
            if (fromParams.slug !== undefined && toParams.slug !== undefined) {
                $rootScope.pageTransitionClass = 'slide';
            } else {
                $rootScope.pageTransitionClass = 'skew';

                $timeout(function () {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }, 650);
            }
        });

});
