angular.module('app')

.controller('showreelCtrl', function ($scope, $rootScope, $uibModalInstance) {

    $scope.closeShowreel = function () {
        $uibModalInstance.dismiss('cancel');
        $rootScope.noScroll = 'auto';
    };

    // This name is being used to match the json structure from case studies
    // and being able to reuse the partial template
    $scope.section = {
        settings: {
            autoPlay: true,
            sources: [
                    {
                        src: "https://static.twelfthman.co/twelfthman-website/homepage/tm-showreel.mp4",
                        type: "video/mp4"
                    },
                    {
                        src: "https://static.twelfthman.co/twelfthman-website/homepage/tm-showreel.webm",
                        type: "video/webm"
                    }
                ],
            theme: "bower_components/videogular-themes-default/videogular.css",
            plugins: {
                poster: "images/pages/homepage/showreel-poster.jpg"
            }
        }
    };
});
