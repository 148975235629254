angular.module('app')

.directive('fixedVideo', function (jsonService, CONFIG, $timeout, $window, $document) {

    var document = $document[0];

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'fixedVideo/fixedVideo.html',
        replace: true,
        link: function(scope) {

            var windowHeight = 0,
                introTextOneHeight = 0,
                introTextTwoHeight = 0,
                pageYOffset = $window.pageYOffset,
                scrollDirection;

            var animatedComponentsResizeFlag = false;

            var introTextOne = document.getElementsByClassName('intro-text-one')[0],
                introTextTwo = document.getElementsByClassName('intro-text-two')[0];
                scope.videoPlaying = false;

            // Helper function to align intro text
            function alignIntroTexts(introTextOne, introTextTwo)
            {
                windowHeight = $window.innerHeight;
                introTextOneHeight = introTextOne.clientHeight;
                introTextTwoHeight = introTextTwo.clientHeight;

                var halfWindow = windowHeight / 2,
                    marginTopOne = halfWindow - introTextOneHeight / 2,
                    marginTopTwo = halfWindow - introTextTwoHeight / 2;

                introTextOne.style.marginTop = marginTopOne + 'px';
                introTextTwo.style.marginTop = marginTopTwo + 'px';
            }

            function removeClass(element, classname)
            {
                if (element.classList.contains(classname)) {
                    element.classList.remove(classname);
                }
            }

            function addClass(element, classname)
            {
                if (!element.classList.contains(classname)) {
                    element.classList.add(classname);
                }
            }

            // function setIntroTextScrolling()
            // {
            //     if (this.pageYOffset > pageYOffset) {
            //         scrollDirection = 'down';
            //     } else {
            //         scrollDirection = 'up';
            //     }

            //     pageYOffset = this.pageYOffset;

            //     if (scrollDirection === 'down' && this.pageYOffset >= introTextOne.offsetTop - windowHeight/4) {

            //         removeClass(introTextOne, 'fade-in');
            //         addClass(introTextOne, 'fade-out');

            //         removeClass(introTextTwo, 'fade-out');
            //         addClass(introTextTwo, 'fade-in');

            //     } else if (scrollDirection === 'up' && this.pageYOffset <= introTextOne.offsetTop - windowHeight/8) {

            //         removeClass(introTextOne, 'fade-out');
            //         addClass(introTextOne, 'fade-in');

            //         removeClass(introTextTwo, 'fade-in');
            //         addClass(introTextTwo, 'fade-out');
            //     }
            // }

            // // Scroll binding
            // angular.element($window).bind('scroll', function() {
            //     setIntroTextScrolling();
            // });

            // Vertical centerer intro text
            angular.element(document).ready(function() {
                // Only tablet and desktop
                if ($window.innerWidth > CONFIG.breakpoints.tablet) {
                    alignIntroTexts(introTextOne, introTextTwo);

                    $timeout(function () {
                        scope.videoPlaying = true;
                        introTextOne.classList.add('fade-in');
                    }, 300);
                }
            });

            angular.element($window).bind('resize', function() {
                if (animatedComponentsResizeFlag === false) {
                    if ($window.innerWidth > CONFIG.breakpoints.tablet) {
                        animatedComponentsResizeFlag = true;
                    }
                }
                alignIntroTexts(introTextOne, introTextTwo);
            });

        }

    };

});
