angular.module('app')

.controller('caseStudyCtrl', function (
    $scope,
    $rootScope,
    $state,
    Page,
    caseStudy,
    works,
    CONFIG,
    $location,
    meta) {

    // Work object coming from resolve
    $scope.caseStudy = caseStudy.data;
    $scope.workList = works.data;

    $scope.descriptionArray = [];

    // Check wether description is single string or array or paragraphs
    var description = $scope.caseStudy.details.description;
    $scope.descriptionArray = description;

    if (typeof description === 'string') {
        $scope.descriptionArray = [description];
    }

    // Metadata set up
    meta.set($scope, {
        title: "TwelfthMan - " + $scope.caseStudy.details.title,
        description: $scope.caseStudy.details.description,
        og_url: $location.absUrl(),
        og_type: "article",
        og_image: $scope.caseStudy.details.thumbnail
    });

    // Get next and previous case studies
    $scope.nextAndPrevCases = Page.getNextAndPrev($scope.workList, $scope.caseStudy);

    // Go to case study by slug
    $scope.goto = function (clientslug, slug, direction) {
        $rootScope.slideDirection = 'slide-' + direction;
        $state.go('case-study', {clientslug: clientslug, slug: slug});
    };

    // Hero image
    $scope.imagesBasePath = CONFIG.workImagesPath + $scope.caseStudy.client + '/' + $scope.caseStudy.slug;
    var heroUrl = $scope.caseStudy.details.hero;

    $scope.caseStudy.heroStyle = {
        backgroundImage: 'url(' + heroUrl + ')'
    };

    $scope.$on('$destroy', function () {
        delete $rootScope.metaData;
    });
});
