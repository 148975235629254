angular.module('app')

.service('Social', function ($http, $window)
{
    var settings = {
        base: 'api/posts?'
    };

    var stringify = $window.JSON.stringify;

    this.findAll = function (filters)
    {
        var filtersString = '';
        var filterValue;


        for (var filter in filters) {

            filterValue = filters[filter];

            filtersString += (filtersString ? '&' : '');
            filtersString += filter + '=' +( angular.isArray(filterValue) ? filterValue.join(',') : stringify(filterValue));
        }

        return $http.get(settings.base + filtersString).then(function (response)
        {
            return response.data;
        });
    };

});
