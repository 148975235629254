angular.module('app')

.factory('utils', function (CONFIG, $sce) {

    return {

        sanitizeHtml: function (html) {
            return $sce.trustAsHtml(html);
        },

        getPartial: function (partial) {
            return CONFIG.partialsPath + partial + '.html';
        },

        isDesktop: function (val) {
            var desktop = CONFIG.breakpoints.tablet;
            return val > desktop;
        }

    };
});
