angular.module('app')

.directive('breakpoint', function ($rootScope, $window, CONFIG) {

    return {
        restrict: 'A',
        link: function (scope) {

            var tablet = CONFIG.breakpoints.tablet,
                mobile = CONFIG.breakpoints.mobile;

            // Get viewport size
            var getViewport = function (currentSize) {
                if (currentSize > tablet) {
                    return 'desktop';
                } else if (currentSize > mobile && currentSize <= tablet) {
                    return 'tablet';
                } else {
                    return 'mobile';
                }
            };

            // Check whether page is being rendered in proper mobile/tablet device
            var whichDevice = function () {
                //$rootScope.device = (deviceDetector.isMobile() || deviceDetector.isTablet()) ? 'is-mobile' : 'is-desktop';
            };

            var setBreakpoints = function () {
                $rootScope.breakpoint = getViewport($window.innerWidth);
                $rootScope.breakpointPixels = $window.innerWidth;
            };

            // On window resize
            angular.element($window).bind('resize', function () {
                scope.$apply(function () {
                    setBreakpoints();
                });
            });

            // Initial load
            whichDevice();
            setBreakpoints();
        }
    };
});
