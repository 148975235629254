angular.module('app')

.factory('mapMarkers', function () {

    var iconConfigStudio = {
        iconUrl: 'images/utils/tm-pin.png',
        iconSize: [26, 34], // size of the icon
        iconAnchor: [13, 33] // Set to [x, y] according to icon dimensions; [12, 40] is roughly center bottom of an 25x41px icon
    };

    var iconConfigLandmark = {
        iconUrl: 'images/utils/tm-pin-landmark.png',
        iconSize: [22, 22], // size of the icon
        iconAnchor: [11, 11] // Set to [x, y] according to icon dimensions; [12, 40] is roughly center bottom of an 25x41px icon
    };

    return {
        // Studios
        london: {
            lat: 51.519298,
            lng: -0.071952,
            focus: true,
            message: "London Studio",
            icon: iconConfigStudio
        },
        copenhagen: {
            lat: 55.697998,
            lng: 12.540663,
            message: "Copenhagen Studio",
            icon: iconConfigStudio
        },

        // London landmarks
        liverpoolst: {
            lat: 51.518752,
            lng: -0.081427,
            message: "Liverpool Street Station",
            icon: iconConfigLandmark
        },
        aldgate: {
            lat: 51.515468,
            lng: -0.072224,
            message: "Aldgate East Station",
            icon: iconConfigLandmark
        },
        shoreditchhighst:{
            lat: 51.523274,
            lng: -0.075364,
            message: "Shoreditch High Street",
            icon: iconConfigLandmark
        },
        spitalfields: {
            lat: 51.519676,
            lng: -0.075175,
            message: "Old Spitalfields Market",
            icon: iconConfigLandmark
        },
        tenbells: {
            lat: 51.519343,
            lng: -0.074282,
            message: "The Ten Bells",
            icon: iconConfigLandmark
        },
        beigelbake: {
            lat: 51.524683,
            lng: -0.071796,
            message: "Beigel Bake",
            icon: iconConfigLandmark
        },
        pride: {
            lat: 51.518884,
            lng: -0.071161,
            message: "The Pride of Spitalfields",
            icon: iconConfigLandmark
        },

        // Copenhagen landmarks
        mikkeller: {
            lat: 55.694801,
            lng: 12.543263,
            message: "Mikkeller & Friends",
            icon: iconConfigLandmark
        },
        superkilen: {
            lat: 55.699365,
            lng: 12.542385,
            message: "Superkilen Park",
            icon: iconConfigLandmark
        }
    };
});
