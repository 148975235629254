angular.module('app')

.config(function ($stateProvider, $urlRouterProvider, $locationProvider, CONFIG) {

    $urlRouterProvider.otherwise("/");

    $stateProvider
        .state('home', {
            url: '/',
            templateUrl: CONFIG.pagesPath + 'home.html',
            controller: 'homeCtrl'
        })
        .state('whatwedo', {
            url: '/whatwedo',
            templateUrl: CONFIG.pagesPath + 'what-we-do.html',
            controller: 'whatWeDoCtrl'
        })
        .state('work', {
            url: '/work',
            templateUrl: CONFIG.pagesPath + 'work.html',
            params: {
                category: 'All',
            },
            controller: 'workCtrl',
            resolve: {
                works: function (Page) {
                    return Page.getAll('works');
                }
            }
        })
        .state('women-in-sport', {
            url: '/women-in-sport',
            templateUrl: CONFIG.pagesPath + 'women-in-sport.html',
            params: {
                category: 'All',
            },
            controller: 'wisCtrl',
            resolve: {
                pieces: function (jsonService) {
                    return jsonService.getContent('women-in-sport/articles').then(function (response) {
                        return response.data;
                    });
                }
            }
        })
        .state('theteam', {
            url: '/the-team',
            templateUrl: CONFIG.pagesPath + 'the-team.html',
            controller: 'theTeamCtrl',
            resolve: {
                team: function (jsonService) {
                    return jsonService.getContent('the-team/team').then(function (response) {
                        return response.data;
                    });
                },
                mapTabs: function (jsonService) {
                    return jsonService.getContent('the-team/map-tabs').then(function (response) {
                        return response.data;
                    });
                },
                vacanciesOpen: function(jsonService) {
                    return jsonService.getContent('common/vacancies').then(function (response) {
                        return response.data.vacanciesOpen;
                    });
                }
            }
        })
        .state('tm-stories', {
            url: '/stories',
            templateUrl: CONFIG.pagesPath + 'stories.html',
            controller: 'storiesCtrl'
        })
        .state('stories', {
            url: '/stories/:slug',
            templateUrl: function ($stateParams) {
                return CONFIG.storiesPath + $stateParams.slug + '.html';
            },
            controller: 'ourStoriesCtrl',
            resolve: {
                stories: function (jsonService) {
                    return jsonService.getContent('our-stories/stories').then(function (response) {
                        return response.data;
                    });
                }
            }
        })
        .state('news', {
            url: '/news',
            templateUrl: CONFIG.pagesPath + 'news.html',
            controller: 'newsCtrl',
            resolve: {
                posts: function (Social)
                {
                    var filters = {
                        approved: 1,
                        where: {

                            // Exclude empty message
                            message: {
                                '!==': ""
                            }
                        },
                        sort: ['date desc']
                    };

                    return Social.findAll(filters);
                }
            }
        })
        .state('case-study', {
            url: '/work/:clientslug/:slug',
            templateUrl: CONFIG.pagesPath + 'case-study.html',
            controller: 'caseStudyCtrl',
            resolve: {
                works: function (Page) {
                    return Page.getAll('works');
                },
                caseStudy: function (Page, $stateParams) {
                    return Page.getSingle('case-studies/' + $stateParams.clientslug + '/' + $stateParams.slug);
                }
            }
        })
        .state('contact', {
            url: '/contact',
            templateUrl: CONFIG.pagesPath + 'contact.html',
            controller: 'contactCtrl',
            resolve: {
                mapTabs: function (jsonService) {
                    return jsonService.getContent('the-team/map-tabs').then(function (response) {
                        return response.data;
                    });
                }
            }
        })
        .state('jobs', {
            url: '/jobs',
            templateUrl: CONFIG.pagesPath + 'jobs.html',
            controller: 'jobsCtrl',
            resolve: {
                vacanciesOpen: function(jsonService) {
                    return jsonService.getContent('common/vacancies').then(function (response) {
                        return response.data.vacanciesOpen;
                    });
                }
            }
        })
        .state('vacancies', {
            url: '/jobs/:slug',
            templateUrl: CONFIG.pagesPath + 'vacancy-template.html',
            controller: 'vacancyCtrl',
            resolve: {
                vacancy: function (Page, $stateParams) {
                    return Page.getSingle('vacancies/' + $stateParams.slug);
                }
            }
        })
        .state('legal', {
            url: '/legal',
            templateUrl: CONFIG.pagesPath + 'legal.html',
        })
        .state('privacypolicy', {
            url: '/privacypolicy',
            templateUrl: CONFIG.pagesPath + 'privacy-policy.html',
        })
        .state('christmas-2017', {
            url: '/christmas-2017',
            templateUrl: CONFIG.pagesPath + 'christmas-2017.html',
            controller: 'christmasCtrl',
            resolve: {
                data: function (jsonService) {
                    return jsonService.getContent('pages/christmas/2017').then(function (response) {
                        return response.data;
                    });
                }
            }
        })
        .state('single-page', {
            url:  '/:slug',
            templateUrl: CONFIG.pagesPath + 'single-page.html',
            controller: 'singlePageCtrl',
            resolve: {
                singlePage: function (Page, $stateParams) {
                    return Page.getSingle('single-pages/' + $stateParams.slug);
                }
            }
        });

    $locationProvider.html5Mode(true);
});
