angular.module('app')

.directive('clientsSlider', function (jsonService, $timeout, CONFIG) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'clientsSlider/clientsSlider.html',
        replace: true,
        scope: true,
        link: function (scope) {

            var sliderTimer;

            scope.slider = {
                "title": "Our clients",
                "intro": "We don't just build relationships with our clients, we become part of the&nbsp;team.",
                "slides": [
                    "images/pages/homepage/the-clients/premier-league.svg",
                    "images/pages/homepage/the-clients/fiba.svg",
                    "images/pages/homepage/the-clients/the-open.svg",
                    "images/pages/homepage/the-clients/euro-2016.svg",
                    "images/pages/homepage/the-clients/ucl.svg",
                    "images/pages/homepage/the-clients/uefa.svg",
                    "images/pages/homepage/the-clients/uel.svg"
                ]
            };

            // Set the index at the first slide.
            scope.currentIndex = 0;

            // Function to move index to next slide.
            scope.next = function ()
            {
                if (scope.currentIndex < scope.slider.slides.length - 1) {
                    scope.currentIndex++;
                } else {
                    scope.currentIndex = 0;
                }
            };

            // Function to move index to previous slide.
            scope.prev = function ()
            {
                if (scope.currentIndex > 0) {
                    scope.currentIndex--;
                } else {
                    scope.currentIndex = scope.slider.slides.length - 1;
                }
            };

            // Call next function to go to next slide every 250ms.
            var sliderMove = function ()
            {
                sliderTimer = $timeout(function () {
                    scope.next();
                    sliderTimer = $timeout(sliderMove, 250);
                }, 250);
            };

            sliderMove();

            // Cancel the timer when the scope is destroyed.
            scope.$on('$destroy', function () {
                if (sliderTimer) {
                    $timeout.cancel(sliderTimer);
                }
            });
        }
    };

});
