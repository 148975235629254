angular.module('app')

.directive('tabMaps', function (jsonService, CONFIG) {

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            tabs: '='
        },
        templateUrl: CONFIG.directivesPath + 'tabMaps/tabMaps.html',
        link: function ($scope)
        {
            $scope.active = $scope.tabs[0];

            $scope.setActive = function (tab)
            {
                $scope.active = tab;
            };

            $scope.getStyle = function ()
            {
                if (!$scope.active.style) {
                    $scope.active.style = {
                        'background-image': 'url(images/pages/theteam/' + $scope.active.thumbnail + ')'
                    };
                }

                return $scope.active.style;
            };
        }
    };

});
