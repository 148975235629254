angular.module('app')

.directive('newsletterButton', function (CONFIG, $http, $timeout) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'newsletterButton/newsletterButton.html',
        replace: true,
        scope: false,
        link: function (scope) {

            scope.signUpActivated = false;
            scope.signUpSuccessful = false;
            scope.signUpError = false;
            scope.userEmail = '';

            var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            scope.activateSignUp = function() {
                scope.signUpActivated = true;
            };

            scope.submitEmail = function() {

                if (emailRegex.test(scope.userEmail) === true) {
                    $http({
                        url: 'https://twelfthman.us9.list-manage.com/subscribe/post-json',
                        params: angular.extend({EMAIL: scope.userEmail}, {u: '10b20291064b2bed36eb1268b', id: '364f035c74', c: 'JSON_CALLBACK'}),
                        method: 'JSONP'
                    }).then(function () {
                        scope.signUpSuccessful = true;
                        scope.signUpActivated = false;
                        scope.userEmail = '';
                        $timeout(function() {
                            scope.signUpSuccessful = false;
                        }, 10000);
                    });
                } else {
                    scope.signUpError = true;
                }

            };

            scope.clearEmail = function() {
                scope.userEmail = '';
                scope.signUpError = false;
            };
        }
    };

});
