angular.module('app')

.directive('navigation', function ($rootScope, jsonService, CONFIG, $timeout, $window, $state) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'navigation/navigation.html',
        replace: true,
        link: function (scope, element) {

            var el = element[0];
            var pageYOffset = $window.pageYOffset;
            var scrollDirection = 'down';

            scope.animateNav = scope.isNavOpen = false;
            $rootScope.noScroll = 'auto';

            // Delay navigation list animation
            $timeout(function () {
                scope.animateNav = true;
            }, 1800);

            // Get navigation items
            scope.getNav = function()
            {
                jsonService.getContent('common/navigation').then(function (d) {
                    scope.nav = d.data;
                });
            }();

            // Open/Close mobile menu
            scope.openCloseMenu = function()
            {
                scope.isNavOpen = !scope.isNavOpen;
                $rootScope.noScroll = scope.isNavOpen ? 'no-scroll' : 'auto';
            };

            // Go to page
            scope.gotoLink = function (state)
            {
                scope.openCloseMenu();
                $state.go(state);
            };

            function setNavigationScrolling()
            {
                // Check what direction the page is being scrolled
                if ($window.pageYOffset > pageYOffset) {
                    scrollDirection = 'down';
                } else {
                    scrollDirection = 'up';
                }

                pageYOffset = $window.pageYOffset;

                // Scrolling down / near content / animate up
                if (scrollDirection === 'down' && $window.pageYOffset >= el.clientHeight) {
                    element.removeClass('show-nav thick');
                    element.addClass('hide-nav');
                }
                // Scrolling up / not near the top / slim navigation
                else if (scrollDirection === 'up' && $window.pageYOffset >= el.clientHeight) {
                    element.removeClass('hide-nav');
                    element.addClass('show-nav slim');
                }
                // Scrolling up / near the top / thick navigation
                else if (scrollDirection === 'up' && $window.pageYOffset < el.clientHeight) {
                    element.removeClass('hide-nav slim');
                    element.addClass('show-nav thick');
                }
            }

            // On scroll event
            angular.element($window).bind("scroll", setNavigationScrolling);
        }
    };

});
