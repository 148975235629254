angular.module('app')

.directive('vacanciesBlock', function (jsonService, CONFIG) {

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            vacanciesOpen: "="
        },
        templateUrl: CONFIG.directivesPath + 'vacanciesBlock/vacanciesBlock.html',
        link: function (scope) {

            scope.vacancies = {};
            scope.evenNumber = false;

            scope.getVacancies = function () {
                jsonService.getContent('common/vacancies').then(function (d) {
                    scope.vacancies = d.data;
                    scope.evenNumber = Object.keys(scope.vacancies.list).length % 2 === 0;
                });
            }();
        }
    };

});
