angular.module('app')

.controller('vacancyCtrl', function ($scope, vacancy, meta) {

    meta.set($scope, {
        title: "TwelfthMan - Vacancies"
    });

    $scope.vacancy = vacancy.data;

});
