angular.module('app')

.directive('currentYear', function ($window) {

    return {
        restrict: 'E',
        scope: false,
        link: function (scope) {
            var currentDate = new Date();
            scope.currentYear = $window.currentYear ? $window.currentYear : currentDate.getFullYear();
        },
        template: '{{currentYear}}'
    };

});
