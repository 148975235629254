angular.module('app')

.controller('workCtrl', function ($scope, $timeout, $stateParams, works, meta) {

    // Private vars go here
    var showAllFilter = 'All';

    // Go through all case studies and get all available tags, sort them
    // alphabetically while always keeping the All tag in first position
    var getFilters = function ()
    {
        var filters = [];
        var caseStudy;
        var tag;

        for (var key in works.data) {

            caseStudy = works.data[key];

            if (angular.isArray(caseStudy.tags)) {

                for (var tagIndex in caseStudy.tags ) {
                    tag = caseStudy.tags[tagIndex];

                    if (filters.indexOf(tag) === -1) {
                        filters.push(tag);
                    }
                }
            }
        }

        // Sort filters and add All filter to beginning
        filters.sort().unshift(showAllFilter);

        return filters;
    };

    var init = function ()
    {
        meta.set($scope, {
            title: "TwelfthMan - Work"
        });

        $scope.currentFilter = $stateParams.category || showAllFilter;
        $scope.filterOptions = getFilters();
        $scope.filteredWork = works.data;
        $scope.workItemLimit = 9;

        // Adding delay for grid animation
        $timeout(function () {
            $scope.works = works.data;
        }, 1300);
    };

    // Increment the limit of shown case studies by the specified count
    $scope.showMoreCaseStudies = function(count)
    {
        $scope.workItemLimit += count;
    };

    // Select a specific tag and prepare an array with all of the relevant case studies
    $scope.selectTag = function(tagName)
    {
        $scope.workItemLimit = 9;
        $scope.currentFilter = tagName;

        if ($scope.currentFilter === showAllFilter) {
            $scope.filteredWork = works.data;
        } else {
            $scope.filteredWork = [];

            angular.forEach(works.data, function(value) {
                if(value.tags.indexOf($scope.currentFilter) !== -1) {
                    $scope.filteredWork.push(value);
                }
            });
        }
    };

    init();
});
