angular.module('app')

.directive('fileUpload', function (CONFIG) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'fileUpload/fileUpload.html',
        replace: true,
        scope: {
            labelText: "@"
        },
        link: function (scope, element, attrs) {

            scope.uploadActive = false;
            scope.fileName = '';

            element.on('change', function(e) {
                scope.uploadActive = true;
                scope.fileName = e.target.files[0].name;
                scope.$apply();
            });

        }
    };

});
