angular.module('app')

.controller('homeCtrl', function ($scope, $rootScope, $timeout, $uibModal) {

    $scope.openShowreel = function () {

        $uibModal.open({
            templateUrl: 'app/partials/showreel.modal.html',
            windowTopClass: 'showreel',
            controller: 'showreelCtrl',
            animation: false
        }).result.catch(function () {
            $rootScope.noScroll = 'auto';
        });

        $rootScope.noScroll = 'no-scroll';
    };

});
