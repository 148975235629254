angular.module('app')

.controller('wisCtrl', function ($scope, $timeout, $stateParams, $window, pieces, meta) {

    // Private vars go here
    var showAllFilter = 'All';

    // Go through all case studies and get all available tags, sort them
    // alphabetically while always keeping the All tag in first position
    var getFilters = function ()
    {
        var filters = [];
        var article;
        var tag;

        for (var key in pieces) {

            article = pieces[key];

            if (angular.isArray(article.tags)) {

                for (var tagIndex in article.tags ) {
                    tag = article.tags[tagIndex];

                    if (filters.indexOf(tag) === -1) {
                        filters.push(tag);
                    }
                }
            }
        }

        // Sort filters and add All filter to beginning
        filters.sort().unshift(showAllFilter);

        return filters;
    };

    var init = function ()
    {
        meta.set($scope, {
            title: "TwelfthMan - Woman in Sport"
        });

        $scope.currentFilter = $stateParams.category || showAllFilter;
        $scope.filterOptions = getFilters();
        $scope.filteredPieces = pieces;
        $scope.piecesItemLimit = 9;

        // Adding delay for grid animation
        $timeout(function () {
            $scope.pieces = pieces;
        }, 1300);
    };

    // Increment the limit of shown case studies by the specified count
    $scope.showMorePieces = function(count)
    {
        $scope.piecesItemLimit += count;
    };

    // Select a specific tag and prepare an array with all of the relevant case studies
    $scope.selectTag = function(tagName)
    {
        $scope.piecesItemLimit = 9;
        $scope.currentFilter = tagName;

        if ($scope.currentFilter === showAllFilter) {
            $scope.filteredPieces = pieces;
        } else {
            $scope.filteredPieces = [];

            angular.forEach(pieces, function(value) {
                if(value.tags.indexOf($scope.currentFilter) !== -1) {
                    $scope.filteredPieces.push(value);
                }
            });
        }
    };
    $scope.openURL = function(url) {
        $window.open(url);
      };

    init();
});
