angular.module('app')

.directive('readMore', function (CONFIG) {

    return {
        restrict: 'E',
        templateUrl: CONFIG.directivesPath + 'readMore/readMore.html',
        replace: true,
        scope: {
            text: '='
        },
        link: function (scope) {

            // Expanded init state for text
            scope.isExpanded = false;

            // Change expand state
            scope.expand = function () {
                scope.isExpanded = !scope.isExpanded;
            };

        }
    };

});
