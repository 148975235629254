angular.module('app')

.controller('WorkCarouselCtrl', function ($scope, jsonService)
{
    $scope.myInterval = 5000;
    $scope.noWrapSlides = false;

    $scope.slides = [
                {
            "active": true,
            "type": "image",
            "url": "images/pages/work/itf/bjk-cup/hero.jpg",
            "content": {
                "title": "Billie Jean King Cup",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "/work/itf/bjk-cup"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/cj/brand-and-identity/hero.jpg",
            "content": {
                "title": "Champions Journal",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "/work/cj/brand-and-identity"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/cricket-world-cup/cwc19/hero.jpg",
            "content": {
                "title": "Cricket World Cup",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "/work/cricket-world-cup/cwc19"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/the-open/origins/hero.jpg",
            "content": {
                "title": "Origins",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/the-open/origins"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/afc/creative-campaign/hero.jpg",
            "content": {
                "title": "Asia Cup 2019",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/afc/creative-campaign"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/rpa/brand-and-identity/hero.jpg",
            "content": {
                "title": "Rugby Players Association",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/rpa/brand-and-identity"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/international-equestrian-federation/fei-house/hero.jpg",
            "content": {
                "title": "International Equestrian Federation",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/international-equestrian-federation/fei-house"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/work/aff/suzuki-cup-2018/hero.jpg",
            "content": {
                "title": "Suzuki Cup 2018",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/aff/suzuki-cup-2018"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/homepage/the-work/the-work-fei-brand.jpg",
            "content": {
                "title": "International Equestrian Federation",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/international-equestrian-federation/brand-and-identity"
                }
            }
        },
        {
            "type": "image",
            "url": "images/pages/homepage/the-work/the-work-chl.jpg",
            "content": {
                "title": "Champions Hockey League",
                "paragraph": "We create, build and protect some of the world's most famous sports brands",
                "link": {
                    "label": "See projects",
                    "path": "work/chl/brand-and-identity"
                }
            }
        },
    ];
});
