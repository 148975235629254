angular.module('app')

.factory('jsonService', function ($http, CONFIG, $window) {

    return {
        getContent: function (name) {
            if (name) {
                var jsonFile = CONFIG.contentPath + name + '.json';

                if ($window.deploymentKey) {
                    jsonFile += '?' + $window.deploymentKey;
                }

                return $http.get(jsonFile);
            }
        }
    };

});
